@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@200;300;400;500;600;700;800&display=swap');
@font-face {
    font-family: 'Font-Solid';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url("./font-awesome/webfonts/fa-solid-900.eot");
    src: url("./font-awesome/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), 
    url("./font-awesome/webfonts/fa-solid-900.woff2") format("woff2"), 
    url("./font-awesome/webfonts/fa-solid-900.woff") format("woff"), 
    url("./font-awesome/webfonts/fa-solid-900.ttf") format("truetype"), 
    url("./font-awesome/webfonts/fa-solid-900.svg#fontawesome") format("svg"); }
  