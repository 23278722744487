


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.fire-work {
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
  .hide {
    opacity: 0;
    visibility: hidden;
  }

  .remove {
    display: none !important;
  }

  .blur {
    filter: blur(12px);
  }
  .container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .stage-container {
    overflow: hidden;
    box-sizing: initial;
  }
  .canvas-container{
      background: #24262b!important;
  }
  @media (max-width: 840px) {
    .stage-container {
      border: none;
      margin: 0;
    }
  }
}
