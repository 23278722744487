






































@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700');
@-webkit-keyframes cross1 {
  0% {
    transform: rotate(45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
  }
  100% {
    transform: rotate(45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
  }
}
@keyframes cross1 {
  0% {
    transform: rotate(45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
  }
  100% {
    transform: rotate(45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
  }
}
@-webkit-keyframes cross2 {
  0% {
    transform: rotate(-45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
  }
  100% {
    transform: rotate(-45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
  }
}
@keyframes cross2 {
  0% {
    transform: rotate(-45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
  }
  100% {
    transform: rotate(-45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
  }
}
@-webkit-keyframes cross1Reverse {
  100% {
    transform: rotate(45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
    opacity: 0;
  }
  0% {
    transform: rotate(45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
}
@keyframes cross1Reverse {
  100% {
    transform: rotate(45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
    opacity: 0;
  }
  0% {
    transform: rotate(45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
}
@-webkit-keyframes cross2Reverse {
  100% {
    transform: rotate(-45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
    opacity: 0;
  }
  0% {
    transform: rotate(-45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
}
@keyframes cross2Reverse {
  100% {
    transform: rotate(-45deg) scaleX(0) scaleY(0.7);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0);
    opacity: 0;
  }
  0% {
    transform: rotate(-45deg) scaleX(1) scaleY(1);
    box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
}
@-webkit-keyframes flip {
  0% {
    transform: rotate(-90deg) rotateY(0deg) translateX(0);
  }
  60% {
    transform: rotate(-90deg) rotateY(200deg) translateX(3vmin);
  }
  80% {
    transform: rotate(-90deg) rotateY(170deg) translateX(3vmin);
  }
  100% {
    transform: rotate(-90deg) rotateY(180deg) translateX(3vmin);
  }
}
@keyframes flip {
  0% {
    transform: rotate(-90deg) rotateY(0deg) translateX(0);
  }
  60% {
    transform: rotate(-90deg) rotateY(200deg) translateX(3vmin);
  }
  80% {
    transform: rotate(-90deg) rotateY(170deg) translateX(3vmin);
  }
  100% {
    transform: rotate(-90deg) rotateY(180deg) translateX(3vmin);
  }
}
@-webkit-keyframes flipReverse {
  100% {
    transform: rotate(-90deg) rotateY(0deg) translateX(0);
  }
  0% {
    transform: rotate(-90deg) rotateY(180deg) translateX(3vmin);
  }
}
@keyframes flipReverse {
  100% {
    transform: rotate(-90deg) rotateY(0deg) translateX(0);
  }
  0% {
    transform: rotate(-90deg) rotateY(180deg) translateX(3vmin);
  }
}
.box-500 {
  width: 100vw;
  height: 100vh;
  background-color: #24262b;
  overflow: hidden;
  font-family: 'Open Sans';
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 1050;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.internal {
  font-size: 25vmin;
  text-align: center;
  color: #fff;
  text-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
  position: relative;
  margin-bottom: 5vmin;
  transition: transform 300ms;
}
.internal:hover {
  transform: scale(1.2);
  cursor: pointer;
}
.internal:hover:before {
  -webkit-animation: flipReverse 300ms;
  animation: flipReverse 300ms;
}
.internal:hover .zero:before {
  -webkit-animation: cross1Reverse 300ms;
  animation: cross1Reverse 300ms;
}
.internal:hover .zero:after {
  -webkit-animation: cross2Reverse 300ms;
  animation: cross2Reverse 300ms;
}
.internal:before {
  content: '(';
  position: absolute;
  transform: rotate(-90deg);
  right: 13vmin;
  bottom: -13vmin;
  display: block;
  font-size: 95%;
  -webkit-animation: flip 1000ms 1.6s ease-in-out forwards;
  animation: flip 1000ms 1.6s ease-in-out forwards;
  transition: transform 300ms;
}

.zero {
  position: relative;
}
.zero:before,
.zero:after {
  position: absolute;
  display: block;
  content: '';
  width: 130%;
  height: 5vmin;
  background: #8dc63f;
  background-image: linear-gradient(90deg, #8dc63f, #009750);
  left: -10%;
  top: 45%;
  box-shadow: 0 1vmin 5vmin rgba(0, 0, 0, 0.5);
}
.zero:before {
  transform: rotate(45deg) scaleX(0) scaleY(0.7);
  -webkit-animation: cross1 300ms 1s ease-in-out forwards;
  animation: cross1 300ms 1s ease-in-out forwards;
}
.zero:after {
  transform: rotate(-45deg) scaleX(0) scaleY(0.7);
  -webkit-animation: cross2 400ms 1.2s ease-in-out forwards;
  animation: cross2 400ms 1.2s ease-in-out forwards;
}
.zero:nth-child(2):before {
  transform: rotate(45deg) scaleX(0) scaleY(0.7);
  -webkit-animation: cross1 400ms 1.1s ease-in-out forwards;
  animation: cross1 400ms 1.1s ease-in-out forwards;
}
.zero:nth-child(2):after {
  transform: rotate(-45deg) scaleX(0) scaleY(0.7);
  -webkit-animation: cross2 500ms 1.3s ease-in-out forwards;
  animation: cross2 500ms 1.3s ease-in-out forwards;
}

.info {
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0 0.5vmin 1vmin rgba(0, 0, 0, 0.5);
  font-size: 200%;
  padding: 0 24px;
  text-align: center;
}

.link {
  position: absolute;
  bottom: 0;
  padding-bottom: 24px;
}
.link a {
  color: #fff;
  opacity: 0.5;
  text-decoration: none;
  font-weight: 400;
  font-size: 90%;
  transition: opacity 300ms;
}
.link a:hover {
  opacity: 0.9;
}
.instructions {
  background: #fefefe;
  width: 300px;
  min-width: 50vmin;
  height: auto;
  padding: 1rem;
  border: 1px solid #dcdcdc;
  border-radius: 0.25rem;
  margin-top: 5vmin;
}
@media (max-width: 650px) {
  .instructions {
    width: 100%;
  }
}
.instructions h2 {
  font-size: 1.25em;
  line-height: 1.3;
  color: #e30528;
}
@media (max-width: 650px) {
  .instructions h2 {
    font-size: 1.05em;
  }
}
.instructions p {
  font-size: 1.15em;
  line-height: 1.5;
  color: #122125;
}
@media (max-width: 650px) {
  .instructions p {
    font-size: 1em;
  }
}
.instructions .step {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 1.5rem;
  margin: 0.5rem 0;
}
.instructions .step .icon {
  width: 1.25rem;
  height: 1.25rem;
  align-self: center;
}
@media (max-width: 650px) {
  .instructions .step .icon {
    width: 1rem;
    height: 1rem;
  }
}
.instructions .step p {
  display: inline-block;
  width: 80%;
  line-height: 1.5;
  padding-left: 0.5rem;
}

@keyframes bouncy {
  0% {
    transform: translateY(10px) translateX(0) rotate(0);
  }
  25% {
    transform: translateX(-10px) rotate(-10deg);
  }
  50% {
    transform: translateX(0) rotate(0deg);
  }
  75% {
    transform: translateX(10px) rotate(10deg);
  }
  100% {
    transform: translateY(10px) translateX(0) rotate(0);
  }
}
