






















// Loader
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
	z-index: 2000;
	#status {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		.spinner {
			width: 40px;
			height: 40px;
			position: relative;
			margin: 100px auto;
			.double-bounce1,
			.double-bounce2 {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				background-color: #5da000;
				opacity: 0.6;
				position: absolute;
				top: 0;
				left: 0;
				animation: sk-bounce 2s infinite ease-in-out;
			}
			.double-bounce2 {
				animation-delay: -1s;
			}
		}
	}
}

@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
	}
	50% {
		transform: scale(1);
	}
}
