@charset "utf-8";
@import url(../font/Font.css);

* {
    --body-font: "DM Sans", Helvetica, Arial, sans-serif;
    --title-font: "DM Sans", Helvetica, Arial, sans-serif;
}

html {
    padding: 0;
    margin: 0;
    background: #fff;
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        /* background-color: #f5f5f5; */
    }
    &::-webkit-scrollbar {
        width: 0px;
        height: 0 px;
        border-radius: 10px;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    }
    &::-webkit-scrollbar-thumb {
        background-color: #666;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: linear-gradient(to top, rgba(1, 150, 135, 0.9), rgba(136, 194, 75, 0.7));
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track-piece:start {
        margin-top: 70px;
        background: transparent;
    }
    &::-webkit-scrollbar-track-piece:end {
        margin-bottom: 15px;
        background: transparent;
    }
}

body {
    font-family: "DM Sans", Helvetica, Arial, sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    color: #727272;
    line-height: 30px;
    padding: 0;
    line-height: 26px;
    word-spacing: 0px;
    overflow: hidden;
}

