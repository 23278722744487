



































































































































































.client-container {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  // background-color: #24262b;
  display: flex;
  align-items: center;
  flex-direction: column;
  scroll-behavior: smooth;
  background-image: url('~@/assets/images/bg2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
  box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
  .client-content {
    width: 100%;
    height: 100%;
    // z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background: url('~@/assets/images/bg7.jpg');
    box-shadow: inset 0 0 0 2000px rgb(27, 29, 33, 0.5);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    @media (max-width: 991px) {
      padding: 10px 0px;
    }
    @media (max-width: 575px) {
      background-repeat: repeat;
      background-size: contain;
    }
  }
}
.ml-custom {
  padding-left: 76px !important;
  @media (max-width: 575px) {
    padding-left: 0px !important;
  }
}
.ml-custom2 {
  padding-left: 240px !important;
}
.mr-cuttom {
  padding-right: 360px;
}
.bg-light {
  background-color: #f7f7fa !important;
}
