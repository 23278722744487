
































































































































































































































































































































































































































































































































































































































































#modal-info-info {
  .has-close {
    font-size: 1rem;
    height: 2rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-weight: bold;
    color: #fff;
  }
  .close-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 11;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 3.75rem;
    height: 3.75rem;
    border: none;
    background: none;
    color: rgba(153, 164, 176, 0.8);
  }
  .userInfo {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    .avatar-box {
      .avatar {
        width: 70px;
        height: 70px;
        border: 2px solid rgb(70, 70, 70);
        border-radius: 50%;
        cursor: pointer;
        vertical-align: middle;
      }
    }
    .name-box {
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      align-items: center;
      margin-top: 0.625rem;
      color: rgb(245, 246, 247);
      font-weight: 600;
      .user-name {
        font-size: 1.25rem;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
      }
      .id {
        font-size: 1.25rem;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        word-break: break-all;
        text-align: center;
      }
    }
    .button-box {
      display: flex;
      justify-content: space-between;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      .button {
        display: block;
        width: 48%;
        height: 3rem;
        margin: 1.25rem 10px 0.5rem 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: rgb(93, 160, 0);
        background-image: conic-gradient(
          from 1turn,
          rgb(69, 136, 2),
          rgb(123, 197, 20)
        );
        font-weight: bold;
        cursor: pointer;
        border-radius: 6.25rem;
        border: none;
        font-size: 14px;
        .button-inner {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
      .button2 {
        display: block;
        width: 48%;
        height: 3rem;
        margin: 1.25rem 0px 0.5rem 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: #581ac4;
        background-image: conic-gradient(from 1turn, #581ac4, #773cfd);
        border-radius: 6.25rem;
        font-weight: bold;
        cursor: pointer;
        border: none;
        font-size: 14px;
        .button-inner {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
      .button3 {
        display: block;
        width: 48%;
        height: 3rem;
        margin: 0rem 10px 1rem 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: #581ac4;
        background-image: conic-gradient(from 1turn, #c43c1a, #fd3c3c);
        border-radius: 6.25rem;
        font-weight: bold;
        cursor: pointer;
        border: none;
        font-size: 14px;
        .button-inner {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
      .button4 {
        display: block;
        width: 48%;
        height: 3rem;
        margin: 0rem 0px 1rem 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: #581ac4;
        background-image: conic-gradient(from 1turn, #c48e1a, #fda33c);
        border-radius: 6.25rem;
        font-weight: bold;
        cursor: pointer;
        border: none;
        font-size: 14px;
        .button-inner {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
    }
    .changePass {
      width: 100%;
      p {
        color: rgba(153, 164, 176, 0.8);
        margin-bottom: 5px;
        margin-top: 20px;
      }
      .form-control {
        border: 1px solid rgb(45, 48, 53);
        background-color: rgba(84, 86, 88, 0.452);
        border-left: none;
        border-radius: 15px;
        color: rgb(123, 197, 20);
        &:focus {
          background: none;
          border: 1px solid rgb(123, 197, 20);
          outline: none;
          box-shadow: none;
          color: rgb(123, 197, 20);
        }
      }
      button {
        display: block;
        width: 11.25rem;
        height: 3rem;
        margin: 1.25rem auto 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: rgb(93, 160, 0);
        background-image: conic-gradient(
          from 1turn,
          rgb(69, 136, 2),
          rgb(123, 197, 20)
        );
        font-weight: bold;
        cursor: pointer;
        border-radius: 6.25rem;
        border: none;
        margin-bottom: 30px;
        .button-inner {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
    }
    .authentication {
      border-radius: 20px;
      background-color: rgb(30, 32, 36);
      padding: 1.25rem 1.25rem 0px;
      padding-bottom: 20px;
      flex: 1 1 0%;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      touch-action: pan-y;
      color: rgba(153, 164, 176, 0.6);
      max-height: 500px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: transparent;
        border-radius: 5px;
      }
      .google-step-summary-top {
        padding: 1rem 1.25rem;
        border-radius: 0.625rem;
        border: 1px solid rgb(45, 48, 53);
        background-color: rgba(45, 48, 53, 0.5);
        font-size: 0.75rem;
        line-height: 1.25rem;
        a {
          text-decoration: underline;
          margin-left: 0.25rem;
          color: rgb(93, 160, 0);
        }
        &.two {
          background-color: transparent;
          border: none;
          padding: 0px 1.25rem;
          margin-top: 0.75rem;
        }
      }
      .qrcode-warp {
        margin-top: 0.75rem;
        background: rgb(245, 246, 247);
        width: 8.625rem;
        height: 8.625rem;
        margin: 1.25rem auto 0px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-radius: 0.75rem;
        font-size: 0px;
        padding: 0.3125rem;
        img {
          width: 8rem;
          height: 8rem;
        }
      }
      .copy-input {
        margin-top: 0.75rem;
        .input {
          margin-top: 1.5rem;
          .input-label {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            line-height: 1em;
            height: 1.25rem;
            margin: 0px 1.125rem 0.375rem;
            color: rgba(153, 164, 176, 0.6);
          }
          .input-control {
            height: 3.5rem;
            position: relative;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            border: 1px solid rgb(45, 48, 53);
            background-color: rgba(45, 48, 53, 0.5);
            opacity: 1;
            border-radius: 1.5rem;
            padding: 0px 1.375rem;
            input {
              flex: 1 1 0%;
              width: 100%;
              height: 100%;
              min-width: 1rem;
              padding: 0px;
              border: none;
              background-color: transparent;
              color: rgb(245, 246, 247);
            }
            .copy-button {
              width: 2.5rem;
              height: 2.5rem;
              margin-top: 0.125rem;
              margin-right: -1.25rem;
              font-weight: bold;
              cursor: pointer;
              border-radius: 6.25rem;
              color: rgba(153, 164, 176, 0.8);
              border: none;
              background: none;
            }
          }
        }
      }
      .twofa-alert {
        margin: 1.125rem 0px 0px;
        padding: 0px 1.25rem 2.25rem;
        border-bottom: 1px solid rgba(176, 179, 191, 0.1);
        font-size: 0.75rem;
        line-height: 1.25rem;
      }
      .codes {
        margin-top: 1.5rem;
        p {
          margin: 0px;
          height: 1.25rem;
          line-height: 1.25rem;
          text-align: center;
        }
        .form-control {
          margin-top: 20px;
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(45, 48, 53, 0.5);
          border-left: none;
          border-radius: 15px;
          color: rgb(123, 197, 20);
          &:focus {
            background: none;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
          }
        }
      }
      button {
        display: block;
        width: 11.25rem;
        height: 3rem;
        margin: 1.25rem auto 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: rgb(93, 160, 0);
        background-image: conic-gradient(
          from 1turn,
          rgb(69, 136, 2),
          rgb(123, 197, 20)
        );
        font-weight: bold;
        cursor: pointer;
        border-radius: 6.25rem;
        border: none;
        .button-inner {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
    }
    .metamask-confirm {
      width: 100%;
      p {
        color: rgba(153, 164, 176, 0.8);
        margin-bottom: 5px;
        margin-top: 20px;
      }
      .form-control {
        border: 1px solid rgb(45, 48, 53);
        background-color: rgba(84, 86, 88, 0.452);
        border-left: none;
        border-radius: 15px;
        color: rgb(123, 197, 20);
        &:focus {
          background: none;
          border: 1px solid rgb(123, 197, 20);
          outline: none;
          box-shadow: none;
        }
      }
      button {
        width: 11.25rem;
        height: 3rem;
        margin: 1.25rem auto 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: rgb(93, 160, 0);
        background-image: conic-gradient(
          from 1turn,
          rgb(69, 136, 2),
          rgb(123, 197, 20)
        );
        font-weight: bold;
        cursor: pointer;
        border-radius: 6.25rem;
        border: none;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
      }
    }
    .metamask-disconfirm {
      width: 100%;
      p {
        color: rgba(153, 164, 176, 0.8);
        margin-bottom: 5px;
        margin-top: 20px;
      }
      .form-control {
        border: 1px solid rgb(45, 48, 53);
        background-color: rgba(84, 86, 88, 0.452);
        border-left: none;
        border-radius: 15px;
        color: rgb(123, 197, 20);
        &:focus {
          background: none;
          border: 1px solid rgb(123, 197, 20);
          outline: none;
          box-shadow: none;
        }
      }
      button {
        display: block;
        width: 11.25rem;
        height: 3rem;
        margin: 1.25rem auto 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: rgb(93, 160, 0);
        background-image: conic-gradient(
          from 1turn,
          rgb(69, 136, 2),
          rgb(123, 197, 20)
        );
        font-weight: bold;
        cursor: pointer;
        border-radius: 6.25rem;
        border: none;
        margin-bottom: 30px;
      }
    }
  }
}
#modal-info-updateInfo {
  .has-close {
    font-size: 1rem;
    height: 2rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-weight: bold;
    color: #fff;
  }
  .close-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 11;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 3.75rem;
    height: 3.75rem;
    border: none;
    background: none;
    color: rgba(153, 164, 176, 0.8);
  }
  .userInfo {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    .avatar-box {
      .avatar {
        width: 70px;
        height: 70px;
        border: 2px solid rgb(70, 70, 70);
        border-radius: 50%;
        cursor: pointer;
        vertical-align: middle;
      }
    }
    .name-box {
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      align-items: center;
      margin-top: 0.625rem;
      color: rgb(245, 246, 247);
      font-weight: 600;
      .user-name {
        font-size: 1.25rem;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
      }
      .id {
        font-size: 1.25rem;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        word-break: break-all;
        text-align: center;
      }
    }
    .button-box {
      display: flex;
      justify-content: space-between;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      .button {
        display: block;
        width: 48%;
        height: 3rem;
        margin: 1.25rem 10px 0.5rem 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: rgb(93, 160, 0);
        background-image: conic-gradient(
          from 1turn,
          rgb(69, 136, 2),
          rgb(123, 197, 20)
        );
        font-weight: bold;
        cursor: pointer;
        border-radius: 6.25rem;
        border: none;
        font-size: 14px;
        .button-inner {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
      .button2 {
        display: block;
        width: 48%;
        height: 3rem;
        margin: 1.25rem 0px 0.5rem 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: #581ac4;
        background-image: conic-gradient(from 1turn, #581ac4, #773cfd);
        border-radius: 6.25rem;
        font-weight: bold;
        cursor: pointer;
        border: none;
        font-size: 14px;
        .button-inner {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
      .button3 {
        display: block;
        width: 48%;
        height: 3rem;
        margin: 0rem 10px 1rem 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: #581ac4;
        background-image: conic-gradient(from 1turn, #c43c1a, #fd3c3c);
        border-radius: 6.25rem;
        font-weight: bold;
        cursor: pointer;
        border: none;
        font-size: 14px;
        .button-inner {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
      .button4 {
        display: block;
        width: 48%;
        height: 3rem;
        margin: 0rem 0px 1rem 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: #581ac4;
        background-image: conic-gradient(from 1turn, #c48e1a, #fda33c);
        border-radius: 6.25rem;
        font-weight: bold;
        cursor: pointer;
        border: none;
        font-size: 14px;
        .button-inner {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
    }
    .changePass {
      width: 100%;
      p {
        color: rgba(153, 164, 176, 0.8);
        margin-bottom: 5px;
        margin-top: 20px;
      }
      .form-control {
        border: 1px solid rgb(45, 48, 53);
        background-color: rgba(84, 86, 88, 0.452);
        border-left: none;
        border-radius: 15px;
        color: rgb(123, 197, 20);
        &:focus {
          background: none;
          border: 1px solid rgb(123, 197, 20);
          outline: none;
          box-shadow: none;
          color: rgb(123, 197, 20);
        }
      }
      button {
        display: block;
        width: 11.25rem;
        height: 3rem;
        margin: 1.25rem auto 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: rgb(93, 160, 0);
        background-image: conic-gradient(
          from 1turn,
          rgb(69, 136, 2),
          rgb(123, 197, 20)
        );
        font-weight: bold;
        cursor: pointer;
        border-radius: 6.25rem;
        border: none;
        margin-bottom: 30px;
        .button-inner {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
    }
    .authentication {
      border-radius: 20px;
      background-color: rgb(30, 32, 36);
      padding: 1.25rem 1.25rem 0px;
      padding-bottom: 20px;
      flex: 1 1 0%;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      touch-action: pan-y;
      color: rgba(153, 164, 176, 0.6);
      max-height: 500px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: transparent;
        border-radius: 5px;
      }
      .google-step-summary-top {
        padding: 1rem 1.25rem;
        border-radius: 0.625rem;
        border: 1px solid rgb(45, 48, 53);
        background-color: rgba(45, 48, 53, 0.5);
        font-size: 0.75rem;
        line-height: 1.25rem;
        a {
          text-decoration: underline;
          margin-left: 0.25rem;
          color: rgb(93, 160, 0);
        }
        &.two {
          background-color: transparent;
          border: none;
          padding: 0px 1.25rem;
          margin-top: 0.75rem;
        }
      }
      .qrcode-warp {
        margin-top: 0.75rem;
        background: rgb(245, 246, 247);
        width: 8.625rem;
        height: 8.625rem;
        margin: 1.25rem auto 0px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-radius: 0.75rem;
        font-size: 0px;
        padding: 0.3125rem;
        img {
          width: 8rem;
          height: 8rem;
        }
      }
      .copy-input {
        margin-top: 0.75rem;
        .input {
          margin-top: 1.5rem;
          .input-label {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            line-height: 1em;
            height: 1.25rem;
            margin: 0px 1.125rem 0.375rem;
            color: rgba(153, 164, 176, 0.6);
          }
          .input-control {
            height: 3.5rem;
            position: relative;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            border: 1px solid rgb(45, 48, 53);
            background-color: rgba(45, 48, 53, 0.5);
            opacity: 1;
            border-radius: 1.5rem;
            padding: 0px 1.375rem;
            input {
              flex: 1 1 0%;
              width: 100%;
              height: 100%;
              min-width: 1rem;
              padding: 0px;
              border: none;
              background-color: transparent;
              color: rgb(245, 246, 247);
            }
            .copy-button {
              width: 2.5rem;
              height: 2.5rem;
              margin-top: 0.125rem;
              margin-right: -1.25rem;
              font-weight: bold;
              cursor: pointer;
              border-radius: 6.25rem;
              color: rgba(153, 164, 176, 0.8);
              border: none;
              background: none;
            }
          }
        }
      }
      .twofa-alert {
        margin: 1.125rem 0px 0px;
        padding: 0px 1.25rem 2.25rem;
        border-bottom: 1px solid rgba(176, 179, 191, 0.1);
        font-size: 0.75rem;
        line-height: 1.25rem;
      }
      .codes {
        margin-top: 1.5rem;
        p {
          margin: 0px;
          height: 1.25rem;
          line-height: 1.25rem;
          text-align: center;
        }
        .form-control {
          margin-top: 20px;
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(45, 48, 53, 0.5);
          border-left: none;
          border-radius: 15px;
          color: rgb(123, 197, 20);
          &:focus {
            background: none;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
          }
        }
      }
      button {
        display: block;
        width: 11.25rem;
        height: 3rem;
        margin: 1.25rem auto 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: rgb(93, 160, 0);
        background-image: conic-gradient(
          from 1turn,
          rgb(69, 136, 2),
          rgb(123, 197, 20)
        );
        font-weight: bold;
        cursor: pointer;
        border-radius: 6.25rem;
        border: none;
        .button-inner {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
    }
    .metamask-confirm {
      width: 100%;
      p {
        color: rgba(153, 164, 176, 0.8);
        margin-bottom: 5px;
        margin-top: 20px;
      }
      .form-control {
        border: 1px solid rgb(45, 48, 53);
        background-color: rgba(84, 86, 88, 0.452);
        border-left: none;
        border-radius: 15px;
        color: rgb(123, 197, 20);
        &:focus {
          background: none;
          border: 1px solid rgb(123, 197, 20);
          outline: none;
          box-shadow: none;
        }
      }
      button {
        width: 11.25rem;
        height: 3rem;
        margin: 1.25rem auto 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: rgb(93, 160, 0);
        background-image: conic-gradient(
          from 1turn,
          rgb(69, 136, 2),
          rgb(123, 197, 20)
        );
        font-weight: bold;
        cursor: pointer;
        border-radius: 6.25rem;
        border: none;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
      }
    }
    .metamask-disconfirm {
      width: 100%;
      p {
        color: rgba(153, 164, 176, 0.8);
        margin-bottom: 5px;
        margin-top: 20px;
      }
      .form-control {
        border: 1px solid rgb(45, 48, 53);
        background-color: rgba(84, 86, 88, 0.452);
        border-left: none;
        border-radius: 15px;
        color: rgb(123, 197, 20);
        &:focus {
          background: none;
          border: 1px solid rgb(123, 197, 20);
          outline: none;
          box-shadow: none;
        }
      }
      button {
        display: block;
        width: 11.25rem;
        height: 3rem;
        margin: 1.25rem auto 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: rgb(93, 160, 0);
        background-image: conic-gradient(
          from 1turn,
          rgb(69, 136, 2),
          rgb(123, 197, 20)
        );
        font-weight: bold;
        cursor: pointer;
        border-radius: 6.25rem;
        border: none;
        margin-bottom: 30px;
      }
    }
  }
}
