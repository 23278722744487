





















































































































































































































































































#header {
  height: 4.25rem;
  position: fixed;
  z-index: 300;
  left: 0px;
  top: 0px;
  right: 0px;
  background-color: rgb(36, 38, 43);
  transition: all 0.2s linear 0s;
  margin-right: 360px;
  margin-left: 240px;
  .header {
    transition: all 0.5s ease 0s;
    max-width: 85.5rem;
    padding: 0px 0.75rem;
    margin: 0px auto;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 100%;
    .left {
      display: flex;
      align-items: center;
      .left-menu-icon {
        width: 1.875rem;
        height: 1.875rem;
        border-radius: 0.9375rem;
        background-color: transparent;
        border: 1px solid rgb(153, 164, 176);
        box-shadow: transparent 0px 2px 4px 0px;
        color: rgba(153, 164, 176, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 480px) {
          width: 25px;
          height: 25px;
          svg {
            font-size: 10px;
          }
        }
      }
    }
    .login-in {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: end;
      justify-content: flex-end;
      p {
        margin: 0px 0.5rem 0px 0px;
        padding: 1rem;
        cursor: pointer;
        color: rgb(93, 160, 0);
        font-weight: bold;
        @media (max-width: 575px) {
          margin: 0px 0.2rem 0px 0px;
          font-size: 13px;
        }
      }
      button {
        border: none;
        padding: 0;
        cursor: pointer;
        background-color: transparent;
      }
      .button {
        display: block;
        border-radius: 6.25rem;
        font-weight: bold;
        cursor: pointer;
        transition: transform 0.2s;
        width: 6.25rem;
        height: 2.5rem;
        margin-right: 3.125rem;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: rgb(93, 160, 0);
        background-image: conic-gradient(
          from 1turn,
          rgb(69, 136, 2),
          rgb(123, 197, 20)
        );
        border: none;
        @media (max-width: 575px) {
          margin: 0px 0.5rem 0px 0px;
          font-size: 13px;
          width: 5rem;
          height: 2rem;
        }
      }
      .chat-btn {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        position: relative;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        &:hover {
          img {
            filter: brightness(200%);
          }
        }
        &.active {
          background-image: linear-gradient(
            to right,
            rgba(105, 179, 1, 0.4),
            rgba(85, 89, 102, 0.4) 55%,
            rgba(85, 89, 102, 0.4)
          );
        }
        svg {
          color: rgba(153, 164, 176, 0.8);
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .right {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: end;
      justify-content: flex-end;
      .wallet-enter {
        margin-right: 1.75rem;
        @media (max-width: 480px) {
          margin-right: 0.5rem;
        }
        .wallet-content {
          background-color: rgb(30, 32, 36);
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
          height: 3rem;
          border-radius: 1.5rem;
          padding-left: 0.875rem;
          line-height: 1;
          -webkit-box-align: center;
          align-items: center;
          position: relative;
          @media (max-width: 480px) {
            padding-left: 10px;
            padding-right: 10px;
          }
          .wallet-content-price {
            position: relative;
            margin: 0.4375rem 0.5rem 0.4375rem 0.4375rem;
            cursor: pointer;
            .wallet-content-price-top {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              font-weight: 800;
              min-width: 5.75rem;
              .coin-icon {
                width: 1rem;
                height: 1rem;
                @media (max-width: 480px) {
                  width: 0.8rem;
                  height: 0.8rem;
                }
              }
              .currency {
                // margin: 0px 0.625rem 0px 0.4375rem;
                color: rgba(153, 164, 176, 0.8);
                margin-right: 3px;
                @media (max-width: 480px) {
                  font-size: 12px;
                  margin: 0px 0.3rem 0px 0rem;
                }
              }
              svg {
                color: rgb(171, 182, 194);
                width: 0.75rem;
                height: 0.75rem;
                transform: rotate(90deg);
                @media (max-width: 480px) {
                  font-size: 12px;
                  width: 0.6rem;
                  height: 0.6rem;
                }
              }
            }
            .wallet-content-price-bottom {
              display: inline-flex;
              vertical-align: middle;
              -webkit-box-align: center;
              align-items: center;
              white-space: nowrap;
              @media (max-width: 480px) {
                font-size: 12px;
              }
              .amount {
                color: rgb(245, 246, 247);
                font-weight: 800;
                .amount-str {
                  width: 6.6em;
                  display: inline-block;
                  .suffix {
                    opacity: 0.5;
                  }
                }
              }
            }
          }
          .button-normal {
            height: 100%;
            width: 7.25rem;
            color: rgb(245, 246, 247);
            box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
            background-color: rgb(93, 160, 0);
            background-image: conic-gradient(
              from 1turn,
              rgb(69, 136, 2),
              rgb(123, 197, 20)
            );
            border-radius: 6.25rem;
            font-weight: bold;
            border: none;
            @media (max-width: 480px) {
              width: 5rem;
              height: 60%;
            }
            .button-inner {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              svg {
                margin-right: 0.3125rem;
                color: rgb(255, 255, 255);
                @media (max-width: 480px) {
                  font-size: 12px;
                }
              }
              span {
                color: rgb(245, 246, 247);
                font-weight: bold;
                cursor: pointer;
                @media (max-width: 480px) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .profile {
        margin-right: 1.75rem;
        width: auto;
        height: 2.5rem;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        background-color: rgb(30, 32, 36);
        border-radius: 1.25rem;
        cursor: pointer;
        position: relative;
        z-index: 9;
        @media (max-width: 480px) {
          margin-right: 1rem;
          width: auto;
        }
        .user-wrap {
          display: flex;
          a {
            @media (max-width: 480px) {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .avatar {
              width: 2.5rem;
              height: 2.5rem;
              border-radius: 50%;
              box-shadow: 0px 0px 15px rgb(255 255 255 / 48%);
              @media (max-width: 480px) {
                width: 1.5rem;
                height: 1.5rem;
              }
            }
          }
          .svg {
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            @media (max-width: 480px) {
              width: 1.5rem;
              height: 1.5rem;
            }
            svg {
              width: 1.4em;
              height: 1.4em;
              color: rgba(153, 164, 176, 0.6);
              font-size: 0.75rem;
              margin-left: -4px;
              @media (max-width: 480px) {
                width: 0.7rem;
                height: 0.7rem;
              }
            }
          }
        }
      }

      .notice {
        margin-right: 6.25rem;
        @media (max-width: 1299px) {
          margin-right: 2.25rem;
        }
        @media (max-width: 480px) {
          margin-right: 0.5rem;
        }
        .notice-btn {
          height: 2.5rem;
          width: 2.5rem;
          border-radius: 50%;
          position: relative;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          @media (max-width: 480px) {
            height: 1rem;
            width: 1rem;
          }
          svg {
            width: 1.375rem;
            height: 1.375rem;
            color: rgba(153, 164, 176, 0.6);
            @media (max-width: 480px) {
              height: 0.9rem;
              width: 0.9rem;
            }
            &:hover {
              color: #fff;
            }
          }
        }
      }
      button {
        border: none;
        padding: 0;
        cursor: pointer;
        background-color: transparent;
        @media (max-width: 480px) {
          width: auto;
        }
        .chat-btn {
          height: 2.5rem;
          width: 2.5rem;
          border-radius: 50%;
          position: relative;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          @media (max-width: 480px) {
            height: 1rem;
            width: 1rem;
          }
          &.active {
            background-image: linear-gradient(
              to right,
              rgba(105, 179, 1, 0.4),
              rgba(85, 89, 102, 0.4) 55%,
              rgba(85, 89, 102, 0.4)
            );
          }
          svg {
            color: #fff;
            @media (max-width: 480px) {
              height: 0.7rem;
              width: 0.7rem;
            }
          }
        }
      }
    }
    .dnn {
      width: 2rem;
      height: 2rem;
      position: relative;
      cursor: pointer;
      @media (max-width: 480px) {
      }
      .dnn-list {
        position: absolute;
        top: 40px;
        left: -30px;
        padding: 0px 10px 10px 10px;
        background-color: rgba(30, 32, 36, 1);
        display: flex;
        flex-direction: column;
        z-index: 1000;
        border-radius: 5px;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.501);
        img {
          width: 50px;
          height: auto;
          margin-top: 10px;
        }
      }
      .img-active {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
      }
    }
    &::after {
      content: '';
      position: absolute;
      left: 0px;
      bottom: -0.75rem;
      width: 100%;
      height: 0.75rem;
      background-image: linear-gradient(rgb(17, 20, 21), rgba(36, 38, 43, 0));
      opacity: 0.25;
    }
  }
}
.ml-customSideBar {
  margin-left: 76px !important;
  padding-left: 0px !important;
  @media (max-width: 991px) {
    margin-left: 0px !important;
  }
}
