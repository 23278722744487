































































































































#modal-listCoin {
  .close-icon {
    position: absolute;
    right: -10px;
    top: -15px;
    z-index: 11;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 3.75rem;
    height: 3.75rem;
    border: none;
    background: none;
    color: rgba(153, 164, 176, 0.8);
  }
  .listCoin-content {
    .listCoin-list {
      box-sizing: border-box;
      height: 100%;
      max-height: 550px;
      overflow-y: auto;
      touch-action: pan-y;
      margin-top: 10px;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: transparent;
        border-radius: 5px;
      }
      .listCoin-item {
        padding: 0.4375rem 0.625rem;
        font-size: 0.875rem;
        border-radius: 1.25rem;
        margin: 0.25rem 0px;
        border: 1px solid transparent;
        min-height: 2.0625rem;
        &.activeCoin {
          border: 1px solid rgb(123, 197, 20);
          border-radius: 20px;
        }
        &:hover {
          background-color: rgba(106, 108, 109, 0.39);
          cursor: pointer;
        }
        .listCoin-item-flex {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          .coin-wrap {
            position: relative;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            .coin-icon {
              width: 1.875rem;
              height: 1.875rem;
              margin-right: 0.9375rem;
              box-shadow: rgb(0 0 0 / 10%) 0px 5px 8px;
              border-radius: 0.9375rem;
            }
          }
          .name-wrap {
            .currency-name {
              color: rgb(255, 255, 255);
              font-weight: 800;
              height: 1.25rem;
              line-height: 1.25rem;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: start;
              justify-content: flex-start;
            }
          }
          .amount-wrap {
            flex-direction: column;
            align-items: flex-end;
            display: flex;
            -webkit-box-align: center;
            margin-left: auto;
            .coin {
              text-align: right;
              color: rgb(255, 255, 255);
              font-weight: 800;
              height: 1.375rem;
              line-height: 1.375rem;
              display: inline-flex;
              vertical-align: middle;
              -webkit-box-align: center;
              align-items: center;
              white-space: nowrap;
              .amount {
                .amount-str {
                  width: 8em;
                  display: inline-block;
                  .suffix {
                    opacity: 0.5;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
