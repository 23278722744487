






































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.widget-content-light {
  color: rgba(153, 164, 176, 0.6);
}
.bonus-content {
  .game {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    justify-self: center;
    padding-bottom: 5px;
    width: 100%;
    color: rgba(153, 164, 176, 0.6);
    button {
      border: none;
      width: 8rem;
      height: 30px;
      margin: 0px 5px;
      color: #f5f6f7;
      box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
      background-image: conic-gradient(from 1turn, #581ac4, #773cfd);
      border-radius: 6.25rem;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
.modal-transaction {
  .has-close {
    position: relative;
    z-index: 10;
    flex: 0 0 auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 3.75rem;
    margin-left: 1.125rem;
    margin-right: 3.75rem;
    transition: all 0.5s ease 0s;
    .title {
      font-size: 1rem;
      margin: 0px;
      font-weight: bold;
      flex: 1 1 0%;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      color: rgb(245, 246, 247);
    }
    .content {
      display: flex;
      button {
        color: rgba(153, 164, 176, 0.8);
        border: none;
        background: none;
        svg {
          display: inline-block;
          vertical-align: top;
          width: 1.125rem;
          height: 1.125rem;
          margin: 0px 0.5rem 0px 0px;
          color: rgba(153, 164, 176, 0.6);
        }
      }
    }
  }
  .close-icon {
    position: absolute;
    right: -10px;
    top: -15px;
    z-index: 11;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 3.75rem;
    height: 3.75rem;
    border: none;
    background: none;
    color: rgba(153, 164, 176, 0.8);
  }
  .transaction-content {
    .action-withdraw-header {
      margin-top: 1rem;
      width: 100%;
      font-size: 0.8125rem;
      .label {
        margin: 0px 0.75rem 0.375rem;
        color: rgba(153, 164, 176, 0.6);
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
      }
      .box {
        background-color: rgba(45, 48, 53, 0.5);
        border: 1px solid rgb(45, 48, 53);
        padding: 0.3125rem 1.25rem;
        width: 100%;
        border-radius: 1.25rem;
        min-height: 3.5rem;
        display: flex;
        flex-direction: column;
        .type {
          display: flex;
          margin-top: 0.3125rem;
          flex-wrap: wrap;
          justify-content: center;
          button {
            color: rgba(153, 164, 176, 0.6);
            background-color: rgb(45, 48, 53);
            cursor: pointer;
            border: 1px solid rgb(45, 48, 53);
            min-width: 4.6875rem;
            padding: 0px 0.625rem;
            height: 1.75rem;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            border-radius: 0.875rem;
            margin-right: 0.1875rem;
            margin-bottom: 10px;
            font-size: 12px;
            &.active {
              color: rgb(255, 255, 255);
              border: 1px solid rgb(93, 160, 0);
              font-weight: 800;
              background-color: rgba(93, 160, 0, 0.15);
            }
          }
        }
        .cont {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          flex: 1 1 auto;
          .form-control {
            margin-top: 20px;
            border: 1px solid rgb(45, 48, 53);
            background-color: rgba(45, 48, 53, 0.5);
            border-left: none;
            border-radius: 15px;
            &:focus {
              background: none;
              border: 1px solid rgb(123, 197, 20);
              outline: none;
              box-shadow: none;
            }
          }
        }
      }
    }
    .transaction-content-action {
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      background-color: rgb(30, 32, 36);
      border-radius: 1.25rem;
      margin: 0.25rem 0.625rem 0.625rem;
      order: 0;
      flex: 0 0 auto;
      font-size: 12px;
      .action-item {
        position: relative;
        z-index: 2;
        height: 5rem;
        width: 5rem;
        padding: 0.625rem 0px;
        text-align: center;
        cursor: pointer;
        line-height: 1;
        border-radius: 1.25rem;
        &:hover {
          background-color: rgba(106, 108, 109, 0.39);
        }
        &.action-active {
          background-color: rgb(93, 160, 0);
          color: rgb(255, 255, 255);
        }
        img {
          width: 1.375rem;
          height: 1.375rem;
          color: rgb(255, 255, 255);
          margin-top: 0.625rem;
        }
        .action-title {
          margin-top: 0.3125rem;
          color: rgb(255, 255, 255);
        }
      }
    }
    .action-deposit {
      color: rgba(153, 164, 176, 0.6);
      height: 100%;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: transparent;
        border-radius: 5px;
      }
      .action-deposit-header {
        margin-top: 1rem;
        width: 100%;
        font-size: 0.8125rem;
        .label {
          margin: 0px 0.75rem 0.375rem;
          color: rgba(153, 164, 176, 0.6);
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
        }
        .box {
          background-color: rgba(45, 48, 53, 0.5);
          border: 1px solid rgb(45, 48, 53);
          padding: 0.3125rem 1.25rem;
          width: 100%;
          border-radius: 1.25rem;
          min-height: 3.5rem;
          display: flex;
          flex-direction: column;
          .cont {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            flex: 1 1 auto;
            .address {
              flex: 1 1 auto;
              word-break: break-all;
              color: rgb(245, 246, 247);
              input {
                border: none;
                background: none;
                color: rgb(245, 246, 247);
                width: 100%;
              }
            }
            .copy-button {
              font-weight: bold;
              cursor: pointer;
              transition: transform 0.2s cubic-bezier(0.36, 0.66, 0.04, 1) 0s;
              border-radius: 6.25rem;
              margin-right: -1.25rem;
              width: 2.5rem;
              height: 1.875rem;
              flex: 0 0 auto;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              border: none;
              background: none;
              svg {
                color: rgba(153, 164, 176, 0.6);
              }
            }
          }
        }
      }
      .action-deposit-qrcode {
        width: 11.125rem;
        height: 11.125rem;
        margin: 1rem auto;
        padding: 0.75rem;
        border-radius: 1.25rem;
        overflow: hidden;
        background-color: rgb(45, 48, 53);
        img {
          width: 100%;
          border-radius: 0.625rem;
          image-rendering: pixelated;
        }
      }
      .action-notification {
        padding: 0.625rem 1rem;
        border-radius: 1.25rem;
        font-size: 1rem;
        border: 1px solid #821f1f;
        color: rgb(255, 255, 255);
        margin-top: 10px;
        p {
          margin: 0.8em 0px;
          color: rgb(255, 255, 255);
        }
      }
    }
    .action-withdraw {
      color: rgba(153, 164, 176, 0.6);
      height: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: transparent;
        border-radius: 5px;
      }
      .action-withdraw-header {
        margin-top: 1rem;
        width: 100%;
        font-size: 0.8125rem;
        .label {
          margin: 0px 0.75rem 0.375rem;
          color: rgba(153, 164, 176, 0.6);
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
        }
        .box {
          background-color: rgba(45, 48, 53, 0.5);
          border: 1px solid rgb(45, 48, 53);
          padding: 0.3125rem 1.25rem;
          width: 100%;
          border-radius: 1.25rem;
          min-height: 3.5rem;
          display: flex;
          flex-direction: column;
          .type {
            display: flex;
            margin-top: 0.3125rem;
            flex-wrap: wrap;
            justify-content: center;
            button {
              color: rgba(153, 164, 176, 0.6);
              background-color: rgb(45, 48, 53);
              cursor: pointer;
              border: 1px solid rgb(45, 48, 53);
              min-width: 4.6875rem;
              padding: 0px 0.625rem;
              height: 1.75rem;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              border-radius: 0.875rem;
              margin-right: 0.1875rem;
              margin-bottom: 10px;
              font-size: 12px;
              &.active {
                color: rgb(255, 255, 255);
                border: 1px solid rgb(93, 160, 0);
                font-weight: 800;
                background-color: rgba(93, 160, 0, 0.15);
              }
            }
          }
          .cont {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            flex: 1 1 auto;
            .form-control {
              margin-top: 20px;
              border: 1px solid rgb(45, 48, 53);
              background-color: rgba(45, 48, 53, 0.5);
              border-left: none;
              border-radius: 15px;
              &:focus {
                background: none;
                border: 1px solid rgb(123, 197, 20);
                outline: none;
                box-shadow: none;
              }
            }
          }
        }
      }
      .action-withdraw-qrcode {
        width: 100%;
        height: auto;
        margin: 1rem auto;
        padding: 0.75rem;
        border-radius: 1.25rem;
        overflow: hidden;
        background-color: rgb(45, 48, 53);
        text-align: left;
        p {
          color: rgba(153, 164, 176, 0.8);
          margin-bottom: 5px;
          margin-top: 20px;
        }
        .form-control {
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(84, 86, 88, 0.452);
          border-left: none;
          border-radius: 15px;
          color: rgb(212, 250, 77);
          &:focus {
            background: none;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
            color: rgb(212, 250, 77);
          }
        }
        .custom-select {
          border: 1px solid rgb(45, 48, 53);
          background: rgba(84, 86, 88, 0.452)
            url('~@/assets/images/home/arrow.png') right 0.75rem center/8px
            10px no-repeat;
          border-left: none;
          border-radius: 15px;
          color: rgb(212, 250, 77);
          &:focus {
            background: rgba(84, 86, 88, 0.452)
              url('~@/assets/images/home/arrow.png') right 0.75rem center/8px
              10px no-repeat;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
          }
          option {
            background-color: #292828;
          }
        }
        .custom-btn {
          width: 100%;
          display: flex;
          justify-content: center;
          .button {
            border: none;
            width: 12.5rem;
            height: 50px;
            margin: 0px;
            color: rgb(245, 246, 247);
            box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
            background-color: rgb(88, 26, 196);
            background-image: conic-gradient(
              from 1turn,
              rgb(88, 26, 196),
              rgb(119, 60, 253)
            );
            border-radius: 6.25rem;
            font-weight: bold;
            cursor: pointer;
            @media (max-width: 480px) {
              width: 10rem;
            }
          }
          .btn-disable {
            cursor: not-allowed !important;
            background-image: conic-gradient(
              from 1turn,
              rgb(124, 92, 179),
              rgb(167, 129, 255)
            );
          }
        }
      }
      .action-notification {
        padding: 0.625rem 1rem;
        border-radius: 1.25rem;
        font-size: 1rem;
        border: 1px solid #821f1f;
        color: rgb(255, 255, 255);
        margin-top: 10px;
        p {
          margin: 0.8em 0px;
          color: rgb(255, 255, 255);
        }
      }
    }
    .listGame {
      color: rgba(153, 164, 176, 0.6);
      height: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: transparent;
        border-radius: 5px;
      }
      .action-game-header {
        margin-top: 1rem;
        width: 100%;
        font-size: 0.8125rem;
        .label {
          margin: 0px 0.75rem 0.375rem;
          color: rgba(153, 164, 176, 0.6);
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
        }
        .box {
          background-color: rgba(45, 48, 53, 0.5);
          border: 1px solid rgb(45, 48, 53);
          padding: 0.3125rem 1.25rem;
          width: 100%;
          border-radius: 1.25rem;
          min-height: 3.5rem;
          display: flex;
          flex-direction: column;
          .type {
            display: flex;
            flex-wrap: wrap;
            margin-top: 0.3125rem;
            justify-content: center;
            button {
              color: rgba(153, 164, 176, 0.6);
              background-color: rgb(45, 48, 53);
              cursor: pointer;
              border: 1px solid rgb(45, 48, 53);
              min-width: 4.6875rem;
              padding: 0px 0.625rem;
              height: 1.75rem;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              border-radius: 0.875rem;
              margin-right: 0.5rem;
              margin-bottom: 10px;
              font-size: 12px;
              &.active {
                color: rgb(255, 255, 255);
                border: 1px solid rgb(93, 160, 0);
                font-weight: 800;
                background-color: rgba(93, 160, 0, 0.15);
              }
            }
          }
          .cont {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            flex: 1 1 auto;
            .form-control {
              margin-top: 20px;
              border: 1px solid rgb(45, 48, 53);
              background-color: rgba(45, 48, 53, 0.5);
              border-left: none;
              border-radius: 15px;
              &:focus {
                background: none;
                border: 1px solid rgb(123, 197, 20);
                outline: none;
                box-shadow: none;
              }
            }
          }
        }
      }
      .action-game-button {
        width: 100%;
        height: auto;
        margin: 1rem auto;
        padding: 0.75rem;
        border-radius: 1.25rem;
        overflow: hidden;
        background-color: rgb(45, 48, 53);
        text-align: center;
        .button {
          border: none;
          width: 8rem;
          height: 50px;
          margin: 0px 5px;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-image: conic-gradient(
            from 1turn,
            rgb(88, 26, 196),
            rgb(119, 60, 253)
          );
          border-radius: 6.25rem;
          font-weight: bold;
          cursor: pointer;
          @media (max-width: 500px) {
            margin-bottom: 10px;
          }
          @media (max-width: 480px) {
            width: 10rem;
          }
          &.bg-withdraw {
            background-image: conic-gradient(from 1turn, #458802, #7bc514);
          }
          &.bg-deposit {
            background-image: conic-gradient(from 1turn, #c48e1a, #fda33c);
          }
          &.bg-play {
            background-image: conic-gradient(from 1turn, #c41a1a, #fd3c3c);
            a {
              color: white;
            }
          }
        }
        .form-deposit-game {
          h5 {
            margin-top: 20px;
            margin-bottom: 0px;
          }
          p {
            color: rgba(153, 164, 176, 0.8);
            margin-bottom: 5px;
            margin-top: 20px;
            text-align: left;
          }
          .form-control {
            border: 1px solid rgb(45, 48, 53);
            background-color: rgba(84, 86, 88, 0.452);
            border-left: none;
            border-radius: 15px;
            color: rgb(212, 250, 77);
            &:focus {
              background: none;
              border: 1px solid rgb(123, 197, 20);
              outline: none;
              box-shadow: none;
              color: rgb(212, 250, 77);
            }
          }
          .button {
            margin-top: 20px;
            background-image: conic-gradient(from 1turn, #458802, #7bc514);
            &.bg-cancel {
              background-image: conic-gradient(from 1turn, #c41a1a, #fd3c3c);
            }
          }
        }
        .form-withdraw-game {
          h5 {
            margin-top: 20px;
            margin-bottom: 0px;
          }
          p {
            color: rgba(153, 164, 176, 0.8);
            margin-bottom: 5px;
            margin-top: 20px;
            text-align: left;
          }
          .form-control {
            border: 1px solid rgb(45, 48, 53);
            background-color: rgba(84, 86, 88, 0.452);
            border-left: none;
            border-radius: 15px;
            color: rgb(212, 250, 77);
            &:focus {
              background: none;
              border: 1px solid rgb(123, 197, 20);
              outline: none;
              box-shadow: none;
              color: rgb(212, 250, 77);
            }
          }
          .button {
            margin-top: 20px;
            background-image: conic-gradient(from 1turn, #c48e1a, #fda33c);
          }
        }
        .form-resgiter-vm555 {
          h5 {
            margin-top: 20px;
            margin-bottom: 0px;
          }
          p {
            color: rgba(153, 164, 176, 0.8);
            margin-bottom: 5px;
            margin-top: 20px;
            text-align: left;
          }
          .form-control {
            border: 1px solid rgb(45, 48, 53);
            background-color: rgba(84, 86, 88, 0.452);
            border-left: none;
            border-radius: 15px;
            color: rgb(212, 250, 77);
            &:focus {
              background: none;
              border: 1px solid rgb(123, 197, 20);
              outline: none;
              box-shadow: none;
              color: rgb(212, 250, 77);
            }
          }
          .button {
            margin-top: 20px;
            background-image: conic-gradient(from 1turn, #c48e1a, #fda33c);
          }
        }
        .notificationPlay {
          h3 {
            color: #c41a1a;
            font-weight: bold;
          }
          p {
            color: #82ff0e;
          }
        }
        span {
          display: flex;
          justify-content: center;
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
      .action-notification {
        padding: 0.625rem 1rem;
        border-radius: 1.25rem;
        font-size: 1rem;
        border: 1px solid #821f1f;
        color: rgb(255, 255, 255);
        margin-top: 10px;
        p {
          margin: 0.8em 0px;
          color: rgb(255, 255, 255);
        }
      }
    }
  }
  .transaction-bottom {
    position: relative;
    padding: 36px 0px;
    border-top: 1px solid rgba(128, 141, 152, 0.1);
    .transaction-bottom-flex {
      margin: 0px auto;
      display: flex;
      flex: 0 0 auto;
      -webkit-box-pack: justify;
      justify-content: space-between;
      background-color: rgb(30, 32, 36);
      width: 25rem;
      border-radius: 2.4375rem;
      height: 3.5rem;
      @media (max-width: 480px) {
        width: 100%;
      }
      .cont {
        display: flex;
        width: 11.5rem;
        padding: 0.625rem 0.625rem 0.625rem 0.625rem;
        line-height: 1.125rem;
        opacity: 0.8;
        color: rgba(153, 164, 176, 0.6);
        font-size: 14px;
        @media (max-width: 480px) {
          font-size: 12px;
        }
      }
      .button {
        border: none;
        width: 12.5rem;
        height: 100%;
        margin: 0px;
        color: rgb(245, 246, 247);
        box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
        background-color: rgb(88, 26, 196);
        background-image: conic-gradient(
          from 1turn,
          rgb(88, 26, 196),
          rgb(119, 60, 253)
        );
        border-radius: 6.25rem;
        font-weight: bold;
        cursor: pointer;
        @media (max-width: 480px) {
          width: 10rem;
        }
      }
    }
    .title {
      margin-bottom: 0.5rem;
      text-align: center;
      color: rgba(153, 164, 176, 0.6);
    }
    .support-wrap {
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      line-height: 1.75rem;
      .support-item {
        width: 48%;
        text-align: center;
        position: relative;
        cursor: pointer;
        color: rgb(255, 255, 255);
        img {
          height: 1.875rem;
          width: auto;
          display: inline-block;
          vertical-align: top;
          margin-right: 0.75rem;
        }
      }
    }
  }
}
.Casino-WM555-btnGroup {
  button {
    @media (max-width: 576px) {
      margin-bottom: 10px;
    }
  }
}
