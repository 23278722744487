





















































































































































































































































































































































































































































































































.sideBar {
  position: fixed;
  z-index: 101;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: auto;
  color: rgba(153, 164, 176, 0.8);
  // background-color: #000;
  @media (max-width: 480px) {
    width: 100%;
  }
  @media (max-width: 992px) {
    z-index: 250;
  }
  // Menu lớn
  .sideBar-scroll {
    overflow: hidden auto;
    width: 240px;
    height: 100%;
    background-color: #6c757d70;
    @media (max-width: 480px) {
      width: 100%;
      background-color: #6c757d70;
    }
    &::-webkit-scrollbar {
      width: 0px !important;
    }
    .sideBar-logo {
      margin-bottom: 1.5rem;
      text-align: center;
      cursor: pointer;
      color: rgba(153, 164, 176, 0.8);
      img {
        height: 4.25rem;
        width: auto;
      }
    }
    .sideBar-item {
      width: 100%;
      cursor: pointer;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      justify-content: space-between;
      font-size: 0.875rem;
      @media (max-width: 480px) {
        position: relative;
      }
      a {
        color: rgba(153, 164, 176, 0.8);
        width: auto;
        height: 2.5rem;
        border-radius: 0px 1.25rem 1.25rem 0px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding-left: 1rem;
        margin-bottom: 0.5rem;
        padding-right: 1.5rem;
        &:hover {
          text-decoration: none;
          font-weight: bold;
          background-image: linear-gradient(
            to right,
            rgb(137 104 57),
            rgb(141 118 74) 33%,
            rgba(85, 89, 102, 0.4)
          );

          color: rgb(245, 246, 247);
        }
        &.router-link-exact-active {
          background-image: linear-gradient(
            to right,
            rgb(137 104 57),
            rgb(141 118 74) 33%,
            rgba(85, 89, 102, 0.4)
          );

          color: rgb(245, 246, 247);
          font-weight: bold;
        }
        img {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 5px;
        }
      }
      .right {
        margin-top: -0.5rem;
        font-size: 0.625rem;
        margin-left: auto;
        margin-right: 0.5rem;
        @media (max-width: 480px) {
          position: absolute;
          left: 155px;
        }
        svg {
          background: none;
          color: rgba(153, 164, 176, 0.8);
          font-size: 0.875rem;
        }
      }
    }
    .reports {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 60px;
      a {
        color: rgba(153, 164, 176, 0.8);
        width: auto;
        height: 2rem;
        border-radius: 0px 1.25rem 1.25rem 0px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding-left: 1rem;
        margin-bottom: 0rem;
        padding-right: 1.5rem;
        font-size: 13px;
        &:hover {
          text-decoration: none;
          font-weight: bold;
          background-image: linear-gradient(
            to right,
            rgba(105, 179, 1, 0.4),
            rgba(85, 89, 102, 0.4) 33%,
            rgba(85, 89, 102, 0.4)
          );
          color: rgb(245, 246, 247);
        }
        &.router-link-exact-active {
          background-image: linear-gradient(
            to right,
            rgba(105, 179, 1, 0.4),
            rgba(85, 89, 102, 0.4) 33%,
            rgba(85, 89, 102, 0.4)
          );
          color: rgb(245, 246, 247);
          font-weight: bold;
        }
        svg {
          font-size: 12px;
          margin-right: 5px;
        }
      }
    }
    .document {
      a {
        padding-left: 60px;
        color: rgba(153, 164, 176, 0.8);
        svg {
          font-size: 12px;
        }
        &:hover {
          color: #fff;
          cursor: pointer;
        }
      }
    }
    .game-list {
      position: absolute;
      left: 100%;
      top: 0px;
      height: 100%;
      width: 15rem;
      background-color: rgb(45, 48, 53);
      padding-top: 8.625rem;
      z-index: 0;
      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 100%;
        width: 0.75rem;
        height: 100%;
        background-image: linear-gradient(
          to right,
          rgb(17, 20, 21),
          rgba(36, 38, 43, 0)
        );
        opacity: 0.14;
      }
      .list-shadow {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        position: relative;
        z-index: 1;
        &::-webkit-scrollbar {
          width: 0px !important;
        }
        .game-item {
          width: 12.5rem;
          height: 2.25rem;
          margin: 0px auto 0.5rem;
          border: 1px solid rgb(45, 48, 53);
          border-radius: 1.25rem;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          font-size: 0.875rem;
          cursor: pointer;
          color: rgba(153, 164, 176, 0.6);
          &:hover {
            // border: 1px solid rgba(93, 160, 0, 0.4);
            color: rgb(245, 246, 247) !important;
            .game-name {
              color: rgb(245, 246, 247) !important;
            }
          }
          &.activeSub {
            border: 1px solid rgba(93, 160, 0, 0.4);
            color: rgb(245, 246, 247) !important;
            .game-name {
              color: rgb(245, 246, 247) !important;
            }
          }
          .game-icon {
            width: 2.5rem;
            height: 1.75rem;
            display: flex;
            -webkit-box-pack: end;
            justify-content: flex-end;
            -webkit-box-align: center;
            align-items: center;
            svg {
              font-size: 0.875rem;
              fill: rgba(153, 164, 176, 0.6);
            }
          }
          .game-name {
            margin-left: 0.875rem;
            font-size: 0.875rem;
            cursor: pointer;
            color: rgba(153, 164, 176, 0.6);
          }
        }
      }
    }
    .game-list2 {
      height: auto;
      width: 100%;
      background-color: rgb(45, 48, 53);
      z-index: 0;
      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 100%;
        width: 0.75rem;
        height: 100%;
        background-image: linear-gradient(
          to right,
          rgb(17, 20, 21),
          rgba(36, 38, 43, 0)
        );
        opacity: 0.14;
      }
      .list-shadow {
        width: 100%;
        height: auto;
        overflow-y: auto;
        position: relative;
        z-index: 1;
        padding: 0px 1.875rem 20px 1.875rem;
        @media (max-width: 375px) {
          padding: 0px 1rem 20px 1rem;
        }
        &::-webkit-scrollbar {
          width: 0px !important;
        }
        .item-wrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          &:nth-child(1) {
            padding-top: 1.5rem;
            margin-top: 0px;
          }
          .game-item {
            width: 22%;
            margin-right: 3%;
            position: relative;
            &::before {
              content: "";
              display: block;
              padding-top: 100%;
              width: 100%;
            }
            .game-area {
              position: absolute;
              top: 0px;
              width: 100%;
              height: 100%;
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              flex-direction: column;
              background: rgba(49, 52, 60, 0.5);
              border-radius: 0.625rem;
              &:hover {
                // border: 1px solid rgba(93, 160, 0, 0.4);
                color: rgb(245, 246, 247) !important;
                .game-name {
                  color: rgb(245, 246, 247) !important;
                }
              }
              &.activeSub {
                border: 1px solid rgba(93, 160, 0, 0.4);
                color: rgb(245, 246, 247) !important;
                .game-name {
                  color: rgb(245, 246, 247) !important;
                }
              }
              .game-icon {
                width: 1.5rem;
                height: 1.5rem;
                svg {
                  font-size: 0.875rem;
                  color: rgba(153, 164, 176, 0.6);
                }
              }
              .game-name {
                margin: 0.375rem auto 0px;
                white-space: nowrap;
                font-size: 0.75rem;
                width: 98%;
                color: rgba(153, 164, 176, 0.6);
                text-align: center;
                @media (max-width: 375px) {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
    .sideBar-item-km {
      width: 100%;
      cursor: pointer;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      justify-content: space-between;
      font-size: 0.875rem;
      position: relative;
      a {
        color: rgba(153, 164, 176, 0.8);
        width: auto;
        height: 2.5rem;
        border-radius: 0px 1.25rem 1.25rem 0px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding-left: 1rem;
        margin-bottom: 0.5rem;
        padding-right: 1.5rem;
        text-decoration: none;
        &:hover {
          background-image: linear-gradient(
            to right,
            rgba(105, 179, 1, 0.4),
            rgba(85, 89, 102, 0.4) 33%,
            rgba(85, 89, 102, 0.4)
          );
          color: #fff;
          font-weight: bold;
        }
        &.router-link-exact-active {
          background-image: linear-gradient(
            to right,
            rgba(105, 179, 1, 0.4),
            rgba(85, 89, 102, 0.4) 33%,
            rgba(85, 89, 102, 0.4)
          );
          color: rgb(245, 246, 247);
          font-weight: bold;
        }
        .km-img {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 1.25rem;
          // background: rgb(23, 24, 27);
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: flex-start;
          cursor: pointer;
          position: relative;
          margin-right: 5px;
          .img-top {
            animation: 2s linear 0s infinite normal none running rotate;
            display: block;
            width: 100%;
            height: 100%;
            // img {
            //   width: 50px;
            //   height: auto;
            // }
          }
          @-webkit-keyframes rotate {
            0% {
              -webkit-transform: rotate(0);
              transform: rotate(0);
            }

            to {
              -webkit-transform: rotate(1turn);
              transform: rotate(1turn);
            }
          }
          @keyframes rotate {
            0% {
              -webkit-transform: rotate(0);
              transform: rotate(0);
            }

            to {
              -webkit-transform: rotate(1turn);
              transform: rotate(1turn);
            }
          }
          .img-bot {
            width: 100%;
            height: 100%;
            vertical-align: top;
            position: relative;
            transform: scaleX(-1);
            margin-left: 0px;
            transform-origin: center center;
            display: block;
          }
          img {
            width: 100%;
            height: 100%;
          }
          &:nth-child(5) {
            img {
              height: 2.125rem;
              width: auto;
            }
          }
          &:nth-child(6) {
            cursor: default;
            img {
              height: 40px;
              width: auto;
            }
          }
          .locked {
            position: absolute;
            background-color: rgba(49, 52, 60, 0.5);
            padding: 0.125rem;
            width: 1.375rem;
            height: 1.375rem;
            border-radius: 0.6875rem;
            right: -0.3125rem;
            top: -0.3125rem;
            z-index: 1;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            svg {
              font-size: 10px;
            }
          }
        }
      }

      .bonus-locked {
        &::after {
          position: absolute;
          content: "";
          inset: 0px;
          background-color: rgba(23, 24, 27, 0.6);
          border-radius: 1.25rem;
        }
      }
    }
    hr {
      background-color: rgba(97, 99, 102, 0.6);
      width: 90%;
    }
    .select {
      width: 100%;
      height: auto;
      color: #fff;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      @media (max-width: 480px) {
        height: 3.5rem;
        font-size: 12px;
      }
      .select-title {
        font-size: 0.75rem;
        color: rgba(153, 164, 176, 0.6);
      }
      .select-trigger {
        position: relative;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        overflow: hidden;
        height: 2.5rem;
        cursor: pointer;
        padding: 0px 1.25rem;
        user-select: none;
        border-radius: 1.25rem;
        background-color: rgba(49, 52, 60, 0.5);
        width: 180px;
        .provider-label {
          width: 90%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .arrow {
          width: 2.5rem;
          height: 100%;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          position: absolute;
          right: 0px;
          top: 0px;
          svg {
            font-size: 0.625rem;
            transition: transform 0.5s cubic-bezier(0.36, 0.66, 0.04, 1) 0s;
            color: rgba(153, 164, 176, 0.6);
          }
        }
      }
      .listTypeGame {
        position: absolute;
        top: 63px;
        width: 200px;
        padding: 10px 10px;
        border-radius: 1.25rem;
        background-color: rgb(27, 29, 33);
        z-index: 10;
        @media (max-width: 480px) {
          top: 50px;
        }
        p {
          &:hover {
            background-color: rgb(45, 48, 53);
            color: #fff;
            width: 100%;
            padding: 5px 10px;
            cursor: pointer;
            border-radius: 20px;
            margin-bottom: 5px;
          }
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media (max-width: 480px) {
            font-size: 12px;
          }
          &.activeType {
            border: 1px solid rgb(123, 197, 20);
            border-radius: 20px;
            padding: 5px 10px;
          }
          span {
            color: rgb(123, 197, 20);
            &.active {
              background-color: rgb(93, 160, 0);
              width: 8px;
              height: 8px;
              border-radius: 50%;
              position: relative;
              &::after {
                content: "";
                position: absolute;
                width: 1.125rem;
                height: 1.125rem;
                left: -0.3125rem;
                top: -0.3125rem;
                background-color: rgba(144, 155, 135, 0.15);
                border-radius: 1.125rem;
              }
            }
          }
        }
      }
    }
    .sideBar-light {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
      overflow: hidden;
      .theme-check {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        background-color: rgba(45, 48, 53, 0.5);
        height: 2.5rem;
        border-radius: 1.25rem;
        cursor: pointer;
        .item {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          padding: 0px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          &.is-active {
            background-image: linear-gradient(
              to right,
              rgba(105, 179, 1, 0.4),
              rgba(85, 89, 102, 0.4) 45%,
              rgba(85, 89, 102, 0.4)
            );
          }
        }
      }
      .theme-word {
        margin-left: 0.75rem;
        font-size: 0.75rem;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        p {
          margin: 0px;
          line-height: 1rem;
          height: 1rem;
          white-space: nowrap;
          color: rgba(153, 164, 176, 0.8);
          &:nth-child(1) {
            color: rgb(245, 246, 247);
            font-weight: bold;
          }
        }
      }
    }
    .sideBar-support {
      width: 100%;
      display: flex;
      justify-content: center;
      @media (max-width: 480px) {
        justify-content: flex-start;
        padding-left: 1.5rem;
      }
      .sideBar-support-box {
        width: 11.25rem;
        height: 2.5rem;
        display: flex;
        border-radius: 1.25rem;
        background: rgba(45, 48, 53, 0.5);
        -webkit-box-align: center;
        align-items: center;
        color: rgb(245, 246, 247);
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .support-icon {
          width: 2.5rem;
          height: 2.5rem;
          min-width: 2.5rem;
          border-radius: 50%;
          background: rgb(93, 160, 0);
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          margin-right: 0.5rem;
        }
        span {
          white-space: nowrap;
          letter-spacing: -0.2px;
        }
      }
    }
    .sideBar-social {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 20px;
      @media (max-width: 480px) {
        text-align: left;
        align-items: flex-start;
        padding-left: 1.5rem;
      }
      p {
        font-size: 0.75rem;
        line-height: 1.25rem;
        width: 80%;
        margin-bottom: 0px;
        text-align: left;
      }
      .social-link {
        display: flex;
        flex-wrap: wrap;
        width: 11.25rem;
        a {
          display: block;
          margin-top: 1rem;
          margin-right: 1.25rem;
          width: 1.5rem;
          img {
            width: 1.25rem;
            height: 1.25rem;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }
  // Menu nhỏ
  .sideBar-small-scroll {
    overflow: hidden auto;
    width: 76px;
    height: 100%;
    background-color: rgb(30, 32, 36);
    &::-webkit-scrollbar {
      width: 0px !important;
    }
    .sideBar-logo {
      padding-top: 0.75rem;
      margin-bottom: 1.5rem;
      text-align: center;
      cursor: pointer;
      color: rgba(153, 164, 176, 0.8);
      img {
        height: 1.5rem;
        width: auto;
      }
    }
    .sideBar-item {
      width: 100%;
      cursor: pointer;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      justify-content: space-between;
      font-size: 0.875rem;
      position: relative;
      margin-bottom: 5px;
      a {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        margin: 0px auto 0.5rem;
        &:hover {
          background-image: linear-gradient(
            to right,
            rgba(105, 179, 1, 0.4),
            rgba(85, 89, 102, 0.4) 33%,
            rgba(85, 89, 102, 0.4)
          );
        }
        &.router-link-exact-active {
          background-image: linear-gradient(
            to right,
            rgba(105, 179, 1, 0.4),
            rgba(85, 89, 102, 0.4) 33%,
            rgba(85, 89, 102, 0.4)
          );
          color: rgb(245, 246, 247);
          font-weight: bold;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        position: absolute;
        right: 5px;
        top: 10px;
        svg {
          background: none;
          color: rgba(153, 164, 176, 0.8);
          font-size: 0.875rem;
        }
      }
    }
    .document {
      position: absolute;
      left: 60px;
      top: 49%;
      width: 130px;
      background-color: #7bc514;
      border-radius: 20px;
      padding-left: 10px;
      a {
        color: #fff;
        svg {
          font-size: 12px;
        }
      }
    }
    .reportsChild {
      position: absolute;
      left: 60px;
      top: 60%;
      width: 155px;
      background-color: #7bc514;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      a {
        color: #fff;
        &.router-link-exact-active {
          color: red;
          text-decoration: none;
        }
      }
    }
    .promotionChild {
      position: absolute;
      left: 60px;
      top: 30%;
      width: 130px;
      background-color: #7bc514;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      a {
        color: #fff;
        &.router-link-exact-active {
          color: red;
          text-decoration: none;
        }
      }
    }
    .game-list {
      position: absolute;
      left: 100%;
      top: 0px;
      height: 100%;
      width: 15rem;
      background-color: rgb(45, 48, 53);
      padding-top: 5.7rem;
      z-index: 0;
      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 100%;
        width: 0.75rem;
        height: 100%;
        background-image: linear-gradient(
          to right,
          rgb(17, 20, 21),
          rgba(36, 38, 43, 0)
        );
        opacity: 0.14;
      }
      .list-shadow {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0px !important;
        }
        .game-item {
          width: 12.5rem;
          height: 2.25rem;
          margin: 0px auto 0.5rem;
          border: 1px solid rgb(45, 48, 53);
          border-radius: 1.25rem;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          font-size: 0.875rem;
          cursor: pointer;
          color: rgba(153, 164, 176, 0.6);
          &:hover {
            border: 1px solid rgba(93, 160, 0, 0.4);
            color: rgb(245, 246, 247) !important;
            .game-name {
              color: rgb(245, 246, 247) !important;
            }
          }
          &.activeSub {
            border: 1px solid rgba(93, 160, 0, 0.4);
            color: rgb(245, 246, 247) !important;
            .game-name {
              color: rgb(245, 246, 247) !important;
            }
          }
          .game-icon {
            width: 2.5rem;
            height: 1.75rem;
            display: flex;
            -webkit-box-pack: end;
            justify-content: flex-end;
            -webkit-box-align: center;
            align-items: center;
            svg {
              font-size: 0.875rem;
              fill: rgba(153, 164, 176, 0.6);
            }
          }
          .game-name {
            margin-left: 0.875rem;
            font-size: 0.875rem;
            cursor: pointer;
            color: rgba(153, 164, 176, 0.6);
          }
        }
      }
    }
    .sideBar-item-km {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 0;
      .km-img {
        width: 2rem;
        height: 2rem;
        border-radius: 1.25rem;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        margin-bottom: 10px;
        a {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          &:hover {
            background-image: linear-gradient(
              to right,
              rgba(105, 179, 1, 0.4),
              rgba(85, 89, 102, 0.4) 33%,
              rgba(85, 89, 102, 0.4)
            );
          }
          &.router-link-exact-active {
            background-image: linear-gradient(
              to right,
              rgba(105, 179, 1, 0.4),
              rgba(85, 89, 102, 0.4) 33%,
              rgba(85, 89, 102, 0.4)
            );
            color: rgb(245, 246, 247);
            font-weight: bold;
          }
          .img-top {
            animation: 2s linear 0s infinite normal none running rotate;
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        @-webkit-keyframes rotate {
          0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
          }

          to {
            -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
          }
        }
        @keyframes rotate {
          0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
          }

          to {
            -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
          }
        }
        .img-bot {
          width: 100%;
          height: 100%;
          vertical-align: top;
          position: relative;
          transform: scaleX(-1);
          margin-left: 0px;
          transform-origin: center center;
          display: block;
        }
        img {
          width: 100%;
          height: 100%;
        }
        &:nth-child(5) {
          img {
            height: 2.125rem;
            width: auto;
          }
        }
        &:nth-child(6) {
          cursor: default;
          img {
            height: 40px;
            width: auto;
          }
        }
        .locked {
          position: absolute;
          background-color: rgba(49, 52, 60, 0.5);
          padding: 0.125rem;
          width: 1.375rem;
          height: 1.375rem;
          border-radius: 0.6875rem;
          right: -0.3125rem;
          top: -0.3125rem;
          z-index: 1;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          svg {
            font-size: 10px;
          }
        }
      }
      .bonus-locked {
        &::after {
          position: absolute;
          content: "";
          inset: 0px;
          background-color: rgba(23, 24, 27, 0.6);
          border-radius: 1.25rem;
        }
      }
    }
    hr {
      background-color: rgba(97, 99, 102, 0.6);
      width: 90%;
    }
    .sideBar-select {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      font-size: 0.75rem;
      .custom-select {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        cursor: pointer;
        margin: 0px auto 1rem;
        border: none;
        background: rgba(45, 48, 53, 0.5);
        color: rgba(153, 164, 176, 0.8);
        padding: 0rem;
        text-align: center;
      }
      svg {
        position: absolute;
        bottom: 30px;
        right: 50px;
        color: rgba(245, 246, 247, 0.808);
        font-size: 12px;
      }
    }
    .select {
      width: 100%;
      height: auto;
      color: #fff;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 480px) {
        height: 1.5rem;
        font-size: 12px;
      }
      .select-trigger {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        cursor: pointer;
        margin: 0px auto 1rem;
        border: none;
        background: rgba(45, 48, 53, 0.5);
        color: rgba(153, 164, 176, 0.8);
        padding: 0rem;
        text-align: center;
        line-height: 2.5rem;
        .provider-label {
          width: 90%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .listTypeGame {
      position: absolute;
      top: 50%;
      left: 85%;
      width: 200px;
      padding: 10px 10px;
      border-radius: 1.25rem;
      background-color: rgb(27, 29, 33);
      z-index: 10;
      @media (max-width: 480px) {
        top: 50px;
      }
      p {
        &:hover {
          background-color: rgb(45, 48, 53);
          color: #fff;
          width: 100%;
          padding: 5px 10px;
          cursor: pointer;
          border-radius: 20px;
          margin-bottom: 5px;
        }
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 480px) {
          font-size: 12px;
        }
        &.activeType {
          border: 1px solid rgb(123, 197, 20);
          border-radius: 20px;
          padding: 5px 10px;
        }
        span {
          color: rgb(123, 197, 20);
          &.active {
            background-color: rgb(93, 160, 0);
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              width: 1.125rem;
              height: 1.125rem;
              left: -0.3125rem;
              top: -0.3125rem;
              background-color: rgba(144, 155, 135, 0.15);
              border-radius: 1.125rem;
            }
          }
        }
      }
    }
    .sideBar-light {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      cursor: pointer;
      margin: 0px auto 1rem;
      .theme-icon-wrap {
        width: 2.5rem;
        height: 2.5rem;
        .theme-icon {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-image: linear-gradient(
            to right,
            rgba(105, 179, 1, 0.4),
            rgba(85, 89, 102, 0.4) 55%,
            rgba(85, 89, 102, 0.4)
          );
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          svg {
            width: 1.4em;
            height: 1.4em;
            fill: rgba(153, 164, 176, 0.8);
          }
        }
      }
    }
    .theme {
      display: flex;
      position: absolute;
      top: 54.5%;
      left: 10px;
      .theme-check {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        background-color: rgb(45, 48, 53);
        height: 2.5rem;
        border-radius: 1.25rem;
        cursor: pointer;
        .item {
          width: 2.5rem;
          height: 2.5em;
          border-radius: 50%;
          padding: 0px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          &.is-active {
            background-image: linear-gradient(
              to right,
              rgba(105, 179, 1, 0.4),
              rgba(85, 89, 102, 0.4) 45%,
              rgba(85, 89, 102, 0.4)
            );
          }
          svg {
            width: 1.4em;
            height: 1.4em;
            fill: rgb(245, 246, 247);
          }
        }
      }
    }
    .sideBar-support {
      width: 100%;
      display: flex;
      justify-content: center;
      .sideBar-support-box {
        width: fit-content;
        height: 2.5rem;
        display: flex;
        border-radius: 1.25rem;
        -webkit-box-align: center;
        align-items: center;
        color: rgb(245, 246, 247);
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .support-icon {
          width: 2.5rem;
          height: 2.5rem;
          min-width: 2.5rem;
          border-radius: 50%;
          background: rgb(93, 160, 0);
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
        }
        span {
          white-space: nowrap;
          letter-spacing: -0.2px;
        }
      }
    }
    .sideBar-social {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 20px;
      p {
        font-size: 0.75rem;
        line-height: 1.25rem;
        width: 100%;
        margin-bottom: 0px;
      }
      .social-link {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        a {
          display: block;
          margin-top: 1rem;
          width: 1.5rem;
          img {
            width: 1.25rem;
            height: 1.25rem;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }
}
.custom-zindex {
  z-index: 0;
}
.christmas {
  text-align: center;
  img {
    height: 190px;
    width: auto;
    object-fit: contain;
    margin: auto;
  }
}
