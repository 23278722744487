




























.gameIframe {
  width: 100%;
  min-height: calc(100vh - 68px);
  height: 100%;
  position: relative;
  z-index: 3;
  background-color: #1e2024;
  .gameIframe-back {
    cursor: pointer;
    margin-bottom: 10px;
    img {
      width: 30px;
      height: 30px;
      &:hover {
        filter: brightness(150%);
      }
    }
    &:hover {
      color: #fff;
    }
  }
  iframe {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 102px);
  }
}
