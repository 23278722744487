// Your variable overrides
$body-bg: #fff;
$body-color: #111;
$EggsBook-color: linear-gradient(90deg, #0d9881, #88c24b) !important;

@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap-vue/src/index.scss";
@import url(./layout.scss);

html,
body {
    font-size: 1rem;
    touch-action: pan-y, pan-x;
    scroll-behavior: smooth;
}
body {
    overflow-x: hidden;
    overflow-y: auto;
}
.modal-open{
    padding-right: 0px !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px !important;
    }
}
@media screen and (max-width: 425px) {
    input,
    .form-control,
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px !important;
    }
}
/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"] {
        font-size: 1rem !important;
    }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"] {
        font-size: 1rem !important;
    }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="url"] {
        font-size: 1rem !important;
    }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="url"] {
        font-size: 1rem !important;
    }
}
.table-responsive {
    background: #1e2024;
}
