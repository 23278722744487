
































































































































































































































.login-header {
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/images/login/bg-login.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px 0px;
  -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.521);
  box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.473);
  .has-close {
    .has-close-img {
      width: auto;
      height: 5rem;
    }
  }
  .close-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 11;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 3.75rem;
    height: 3.75rem;
    background: none;
    border: none;
    svg {
      width: 1.4em;
      height: 1.4em;
      transition: transform 0.5s cubic-bezier(0.36, 0.66, 0.04, 1) 0s;
      cursor: pointer;
      font-size: 0.75rem;
      color: rgb(255, 255, 255);
    }
  }
  .welcome {
    margin: 20px 0px;
    z-index: 1;
    color: rgb(245, 246, 247);
    padding: 0px 1em;
    @media (max-width: 575px) {
      height: 5rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .msg1 {
      font-size: 1.5rem;
      font-weight: bold;
      width: 100%;
      text-align: center;
      line-height: 1.2;
      @media (max-width: 575px) {
        font-size: 1rem;
        width: 90%;
        line-height: 1.5;
      }
    }
    .msg2 {
      font-size: 1rem;
      font-weight: bold;
      width: 100%;
      text-align: center;
      line-height: 1.2;
      margin-top: 20px;
      @media (max-width: 575px) {
        font-size: 1rem;
        width: 11.375rem;
        line-height: 1.5;
      }
    }
    img {
      height: 13.75rem;
      position: absolute;
      right: 20px;
      top: 3rem;
      z-index: 0;
      @media (max-width: 575px) {
        height: 10rem;
      }
      @media (max-width: 375px) {
        height: 9rem;
      }
    }
  }
}
.form-login {
  width: 100%;
  height: auto;
  // border-radius: 1.25rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: rgb(23, 24, 27);
  position: relative;
  z-index: 1;
  .login {
    background-color: rgb(30, 32, 36);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: auto;
    .box {
      padding: 1.5rem 1.25rem;
      @media (max-width: 575px) {
        padding: 1rem 1.25rem;
      }
      .input {
        margin-top: 1rem;
        &:nth-child(1) {
          margin-top: 0.25rem;
        }
        .input-label {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          line-height: 1em;
          height: 1.25rem;
          margin: 0px 0.75rem 0.375rem 1.2rem;
          color: rgba(153, 164, 176, 0.6);
          a {
            color: rgb(93, 160, 0);
            text-decoration: none;
            cursor: pointer;
          }
          @media (max-width: 575px) {
            font-size: 14px;
          }
        }
        .input-control {
          position: relative;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          border-radius: 1.25rem;
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(45, 48, 53, 0.5);
          height: 3.5rem;
          opacity: 1;
          @media (max-width: 575px) {
            height: 2.5rem;
            font-size: 14px;
          }
          input {
            width: 100%;
            height: 100%;
            padding: 20px;
            border: none;
            background: none;
            color: rgb(245, 246, 247);
            border-radius: 1.25rem;
            &:focus-visible {
              outline: none;
              border: 1px solid rgb(93, 160, 0);
            }
          }
        }
      }
    }
    hr {
      height: 1px;
      margin: 0px;
      border: none;
      background-color: rgba(62, 72, 79, 0.3);
    }
    .buttons {
      padding: 1.25rem 2.5rem;
      display: flex;
      .button {
        border: none;
        &:nth-child(1) {
          width: 15rem;
          flex: 0 0 auto;
          margin-right: 0.625rem;
          height: 3.625rem;
          color: rgb(245, 246, 247);
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: rgb(88, 26, 196);
          background-image: conic-gradient(
            from 1turn,
            rgb(88, 26, 196),
            rgb(119, 60, 253)
          );
          font-weight: bold;
          cursor: pointer;
          transition: transform 0.2s cubic-bezier(0.36, 0.66, 0.04, 1) 0s;
          border-radius: 6.25rem;
          display: block;
          @media (max-width: 575px) {
            width: 13rem;
            height: 2.5rem;
            font-size: 14px;
          }
          @media (max-width: 480px) {
            width: 11rem;
          }
          @media (max-width: 375px) {
            width: 50%;
          }
        }
        &:nth-child(2) {
          color: rgb(245, 246, 247);
          background-color: rgb(49, 52, 60);
          flex: 1 1 0%;
          height: 3.625rem;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          font-weight: bold;
          cursor: pointer;
          transition: transform 0.2s;
          display: block;
          width: 100%;
          border-radius: 6.25rem;
          @media (max-width: 575px) {
            height: 2.5rem;
            font-size: 14px;
          }
        }
        .button-inner {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          span {
            color: rgb(245, 246, 247);
            font-weight: bold;
            cursor: pointer;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .login-fotter {
    padding: 1rem 2.5rem 1.25rem;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    .box-title {
      text-align: center;
      width: 100%;
      line-height: 1;
      margin-bottom: 0.875rem;
      color: rgba(153, 164, 176, 0.6);
      font-size: 18px;
    }
    .other-group {
      border-radius: 1.75rem;
      background-color: rgba(49, 52, 60, 0.5);
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      button {
        display: flex;
        height: 4.5rem;
        width: 4.5rem;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        border: none;
        background: none;
        img {
          width: 50px;
          height: auto;
        }
      }
      .line {
        width: 1px;
        margin: 0.875rem 0px;
        background-color: rgb(45, 49, 55);
      }
    }
  }
}
